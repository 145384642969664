import React, { useState } from "react";
import { Section } from "./Section";
import { ContactColumn, ImageColumn } from "./Common";
import logoImg from "../images/instapay-logo-white.png";
import Link from "gatsby-link";
import { SectionBackgroundWave } from "./SectionBackgroundWave";
import BlueCardWave from "../images/blue-card-wave.png";
import BlueWave from "../images/blue-wave.png";

const columns = {
  ContentfulContactColumn: ContactColumn,
  ContentfulImageColumn: ImageColumn,
};

export const SectionContactFormSpecialOffer = ({
  spaceBottom,
  spaceTop,
  backgroundColor,
  columnLeft,
  columnRight,
}) => {
  const [isSent, setIsSent] = useState(false);
  const LeftColumnTag = columns[columnLeft.__typename];
  const RightColumnTag = columns[columnRight.__typename];

  columnRight.formActionUrl =
    "https://europe-west1-instapay-prod.cloudfunctions.net/apiv2/special-offer-contact";

  if (columnLeft.__typename === "ContentfulImageColumn") {
    columnLeft.position = "left";
    columnLeft.before = <Logo />;
    columnLeft.className = "pr-0 pr-lg-4rem";
    columnLeft.imageClass = "w-70 pt-1rem";
    columnLeft.colClassName = `col-12 col-lg-7 pb-3rem pb-lg-${spaceBottom}rem pt-lg-${spaceTop}rem`;
    columnRight.before = <div className="h-lg-5rem" />;
    columnRight.className = `col-12 col-lg-5 pl-lg-0 pl-lg-4rem bgc-almost-white pb-2rem pb-lg-${spaceBottom}rem pt-3rem pt-lg-${spaceTop}rem`;
    columnRight.position = "right";
  }

  if (
    columnRight.__typename === "ContentfulContactColumn" &&
    (backgroundColor === "blue-card-wave" || backgroundColor === "blue-wave")
  ) {
    columnRight.isSent = isSent;
    columnRight.setIsSent = setIsSent;
    columnLeft.colClassName = `col-12 col-lg-6 pb-0 pb-lg-${spaceBottom}rem pt-lg-${spaceTop}rem`;
    columnRight.className = `col-12 col-lg-6 pl-lg-0 px-lg-4rem ${
      isSent ? "bgc-white" : "bgc-almost-white"
    } pb-2rem pb-lg-${spaceBottom}rem pt-3rem pt-lg-${spaceTop}rem position-lg-absolute er-0 round-sm-1rem shadow-special-offer-form`;
    columnRight.style = { zIndex: 99 };
    columnRight.fieldWidth = 100;
    columnLeft.before = (
      <>
        <Logo className="etn-3 position-lg-absolute" />
        <div className="h-0 h-lg-5rem modal-content" />
      </>
    );
    columnRight.before = "";
  }

  if (columnRight.__typename === "ContentfulImageColumn") {
    columnRight.position = "left";
  } else {
    columnRight.position = "right";
  }

  switch (backgroundColor) {
    case "gradient-tTb-blue":
      return (
        <section className="gradient-tTb-blue" id="book-demo">
          <div className="bg-lg-split-33">
            <div className="container-fluid container-lg">
              <div className="row">
                <div className="col-12 col-xl-11 mx-auto">
                  <div className="row c-black">
                    {LeftColumnTag && <LeftColumnTag {...columnLeft} />}
                    {RightColumnTag && <RightColumnTag {...columnRight} />}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      );
    case "blue-card-wave":
      return (
        <SectionBackgroundWave
          getOffsetTop={() => 200}
          paddingBottom={10}
          className={"pt-0 pb-0 pb-sm-2rem pt-lg-5rem pb-lg-5rem"}
          waveImage={BlueCardWave}
          children={
            <>
              {LeftColumnTag && <LeftColumnTag {...columnLeft} />}
              {RightColumnTag && <RightColumnTag {...columnRight} />}
            </>
          }
        />
      );
    case "blue-wave":
      return (
        <SectionBackgroundWave
          getOffsetTop={() => 200}
          paddingBottom={10}
          className={"pt-0 pb-0 pb-sm-2rem pt-lg-5rem pb-lg-5rem"}
          waveImage={BlueWave}
          children={
            <>
              {LeftColumnTag && <LeftColumnTag {...columnLeft} />}
              {RightColumnTag && <RightColumnTag {...columnRight} />}
            </>
          }
        />
      );
    default:
      return (
        <Section
          width={11}
          className={`py-3rem pb-lg-${spaceBottom}rem pt-lg-${spaceTop}rem ${backgroundColor}`}
        >
          {LeftColumnTag && <LeftColumnTag {...columnLeft} />}
          {RightColumnTag && <RightColumnTag {...columnRight} />}
        </Section>
      );
  }
};

const Logo = ({ className }) => (
  <div className={`h-5rem pt-2rem pb-4rem pt-lg-0 pb-lg-0 ${className}`}>
    <Link to="/">
      <img src={logoImg} alt="Instapay Logo" className="w-7rem pb-2rem" />
    </Link>
  </div>
);
