import React from "react";
import { Section } from "./Section";
import { ContactColumn, ImageColumn } from "./Common";

const columns = {
  ContentfulContactColumn: ContactColumn,
  ContentfulImageColumn: ImageColumn,
};

export const SectionTwoColumns = ({
  spaceBottom,
  spaceTop,
  backgroundColor,
  columnLeft,
  columnRight,
}) => {
  const LeftColumnTag = columns[columnLeft.__typename];
  const RightColumnTag = columns[columnRight.__typename];

  if (columnLeft.__typename === "ContentfulImageColumn") {
    columnLeft.position = "left";
  } else {
    columnLeft.position = "right";
  }

  if (columnRight.__typename === "ContentfulImageColumn") {
    columnRight.position = "left";
  } else {
    columnRight.position = "right";
  }

  return backgroundColor === "gradient-tTb-blue" ? (
    <section className="gradient-tTb-blue" id="contact">
      <div className="bg-lg-split py-0 py-lg-5rem">
        <div className="container-fluid container-lg">
          <div className="row">
            <div className="col-12 col-xl-11 mx-auto">
              <div className="row">
                {LeftColumnTag && <LeftColumnTag {...columnLeft} />}
                {RightColumnTag && <RightColumnTag {...columnRight} />}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : (
    <Section
      width={11}
      className={`py-3rem pb-lg-${spaceBottom}rem pt-lg-${spaceTop}rem ${backgroundColor}`}
    >
      {LeftColumnTag && <LeftColumnTag {...columnLeft} />}
      {RightColumnTag && <RightColumnTag {...columnRight} />}
    </Section>
  );
};
