import React from "react";
import { Section } from "./Section";
import { WaveBackground } from "./WaveBackground";

export const SectionBackgroundWave = ({
  children,
  getOffsetTop,
  paddingBottom,
  className,
  waveImage,
}) => (
  <>
    <WaveBackground getOffsetTop={getOffsetTop} image={waveImage} />
    <div className="position-relative bg-md-blue-gradient">
      <Section width={11} className={className || "py-5rem"}>
        {children}
      </Section>
    </div>
    <div className={`h-0 h-lg-${paddingBottom || 5}rem`} />
  </>
);
