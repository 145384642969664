import React, { useEffect, useRef, useState } from "react";
import { Section } from "./Section";
import { Package } from "./Package";
import PrimaryLinkButton from "./PrimaryLinkButton";
import { useWindowSize } from "../hooks/useWindowSize";

export const Packages = ({ packages, button }) => {
  const windowSize = useWindowSize();
  const packageRefs = useRef([]);
  const [packageHeight, setPackageHeight] = useState("790px");

  useEffect(() => {
    if (packages.length !== packageRefs.current.length || !windowSize.width) {
      return;
    }

    if (windowSize.width < 768) {
      setPackageHeight("auto");

      return;
    }

    const height = Object.values(packageRefs.current).reduce(
      (acc, packageRef) => {
        const height = packageRef.getBoundingClientRect().height;

        if (height > acc) {
          acc = height;
        }

        return acc;
      },
      0
    );

    setPackageHeight(height);
  }, [packageRefs, windowSize.width]);

  return (
    <Section bgColor={1} secondRowClassName="justify-content-center">
      {packages.map((item, index) => (
        <Package
          {...{ ...item, packageRefs, packageHeight, index }}
          key={item.title}
        />
      ))}
      {button && (
        <div className="col-12 d-flex justify-content-center pt-4rem">
          <PrimaryLinkButton button={button} />
        </div>
      )}
    </Section>
  );
};
