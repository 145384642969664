import React from "react";
import { Section } from "./Section";
import counter1 from "../images/counter/counter-1.png";
import counter2 from "../images/counter/counter-2.png";
import counter3 from "../images/counter/counter-3.png";
const images = [counter1, counter2, counter3];

export const Counters = ({
  title,
  items,
  backgroundColor,
  spaceBottom,
  spaceTop,
}) => {
  return (
    <Section
      width={11}
      className={`pb-${spaceBottom}rem pt-${spaceTop}rem ${backgroundColor}`}
    >
      {title && (
        <h2
          className={`fs-1_75 font-weight-bold col-12 text-center pb-3rem`}
          data-aos="fade-up"
          data-aos-delay="50"
        >
          {title}
        </h2>
      )}
      {items.map(({ value, caption }, index) => (
        <Column
          key={index}
          caption={caption}
          value={value}
          index={index}
          colNumber={getColNumber(items.length)}
        />
      ))}
    </Section>
  );
};

const Column = ({ value, caption, colNumber, index }) => (
  <div
    className={`text-center col-12 col-md-${colNumber} pb-3rem`}
    data-aos="fade-up"
    data-aos-delay={`${100 + index * 50}`}
  >
    {value && (
      <div className="fs-2_25 fs-xxl-3 font-weight-bold c-blue">{value}</div>
    )}
    {caption && (
      <div className="fs-1_125 font-weight-bold pb-1rem">{caption}</div>
    )}
    <img src={images[index]} alt="icon" />
  </div>
);

const getColNumber = (columnsNumber) => {
  switch (columnsNumber) {
    case 4:
      return 3;
    case 3:
      return 4;
    case 2:
      return 6;
    case 1:
    default:
      return 12;
  }
};
