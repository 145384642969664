import React, { useState } from "react";
import { RichText } from "./RichText";

export const Package = (props) => {
  const { packageHeight, index } = props;
  const [borderColor, setBorderColor] = useState("");
  const handleMouseOver = () => setBorderColor("bc-blue");
  const handleMouseLeave = () => setBorderColor("");

  return (
    <div className="col-12 col-md-6 col-xl-3 pb-2rem">
      <div
        className={`py-3rem border bw-1 round-1rem shadow position-relative ${borderColor}`}
        onMouseOver={handleMouseOver}
        onFocus={handleMouseOver}
        onMouseLeave={handleMouseLeave}
        style={{
          height:
            typeof packageHeight === "number" ? `${packageHeight}px` : "auto",
        }}
      >
        <div
          ref={(el) => {
            props.packageRefs.current[index] = el;
          }}
        >
          <PackageContent {...props} />
        </div>
        <div
          className={`py-3rem border bw-3 round-1rem position-absolute bgc-white w-100 ${borderColor}`}
          style={{
            zIndex: 1,
            top: "-3px",
            height:
              typeof packageHeight === "number"
                ? `${packageHeight + 3}px`
                : "auto",
          }}
        >
          <PackageContent {...props} />
        </div>
      </div>
    </div>
  );
};

const PackageContent = ({ title, price, oldPrice, description }) => (
  <>
    <h3 className="d-flex fs-1_25 fs-xxl-1_5 pb-6 font-weight-bold text-uppercase justify-content-center c-black">
      {title}
    </h3>
    <div className="d-flex font-weight-bold justify-content-center c-gray-200">
      <div className="pricing__oldPrice">{oldPrice}</div>
    </div>
    <div className="d-flex fs-2_25 fs-xxl-2_75 pb-6 font-weight-bold justify-content-center c-blue">
      {price}
    </div>
    <RichText
      className={`px-2rem min-h-16rem c-black fs-0_875`}
      text={description.raw}
      formatOptions={{
        checkIcon: {
          iconClass: "fs-1_5 sw-2",
          textClass: "pl-3",
        },
      }}
    />
  </>
);
