import React, { useRef, useEffect, useState } from "react";
import { RichText } from "./RichText";
import { Section } from "./Section";
import { useWindowSize } from "../hooks/useWindowSize";
import { AnchorLink } from "gatsby-plugin-anchor-links";

export const SectionColumns = ({
  columns,
  backgroundColor,
  spaceBottom,
  spaceTop,
  columnsNumber = 2,
  type = "columns",
}) => {
  const colNumber = getColNumber(columnsNumber);

  return (
    <Section
      width={11}
      className={`pb-${spaceBottom}rem pt-${spaceTop}rem ${backgroundColor}`}
    >
      {type === "columns" &&
        columns.map((column, index) => (
          <Column
            {...{
              ...column,
              index,
              columnsNumber,
              colNumber,
            }}
            key={index}
          />
        ))}
      {type === "cards" && (
        <Cards
          columns={columns}
          columnsNumber={columnsNumber}
          colNumber={colNumber}
        />
      )}
    </Section>
  );
};

const Column = ({
  title,
  text,
  colNumber,
  index,
  columnsNumber,
  image,
  titleAlign = "",
  textAlign = "",
  imageAlign = "",
}) => (
  <div
    className={`col-12 col-md-${colNumber} ${getColClass(
      columnsNumber,
      index
    )}`}
  >
    {image && (
      <div className={`pb-6 ${imageAlign}`}>
        <img src={image.fluid.src} alt="icon" />
      </div>
    )}
    {title && (
      <div className={`pb-1rem ${titleAlign}`}>
        <h5 className="fs-1_125 font-weight-bold">{title}</h5>
      </div>
    )}
    {text && (
      <div className={textAlign}>
        <RichText
          text={text.raw}
          blocks={{ paragraph: { className: "fs-1" } }}
        />
      </div>
    )}
  </div>
);

const Cards = ({ columns, columnsNumber, colNumber }) => {
  const windowSize = useWindowSize();
  const cardRefs = useRef([]);
  const [cardHeight, setCardHeight] = useState("0px");

  useEffect(() => {
    if (columns.length !== cardRefs.current.length || !windowSize.width) {
      return;
    }

    if (windowSize.width < 1070) {
      setCardHeight(`auto`);

      return;
    }

    const height = Object.values(cardRefs.current).reduce((acc, cardRef) => {
      const height = cardRef.getBoundingClientRect().height;

      if (height > acc) {
        acc = height;
      }

      return acc;
    }, 0);

    setCardHeight(`${height}px`);
  }, [cardRefs, windowSize.width]);

  return columns.map((column, index) => (
    <Card
      title={column.title}
      text={column.text}
      image={column.image}
      link={column.link}
      titleAlign={column.titleAlign}
      textAlign={column.textAlign}
      columnsNumber={columnsNumber}
      colNumber={colNumber}
      cardRefs={cardRefs}
      cardHeight={cardHeight}
      index={index}
      key={index}
    />
  ));
};

const Card = ({
  title,
  text,
  colNumber,
  image,
  link,
  cardRefs,
  cardHeight,
  index,
  titleAlign = "",
  textAlign = "",
}) => (
  <div className={`col-12 col-lg-${colNumber}`}>
    {image &&
      (link ? (
        <AnchorLink to={link}>
          <div
            className="bgImage h-11rem w-100"
            style={{ backgroundImage: `url(${image.fluid.src})` }}
          />
        </AnchorLink>
      ) : (
        <div
          className="bgImage h-11rem w-100"
          style={{ backgroundImage: `url(${image.fluid.src})` }}
        />
      ))}
    <div
      className="bgc-white"
      ref={(el) => {
        cardRefs.current[index] = el;
      }}
      style={{ minHeight: cardHeight }}
    >
      <div className="p-4">
        {title &&
          (link ? (
            <AnchorLink to={link} className="text-decoration-none c-black">
              <div className={`pb-2 ${titleAlign}`}>
                <h5 className="fs-1_5 font-weight-bold m-0">{title}</h5>
              </div>
            </AnchorLink>
          ) : (
            <div className={`pb-2 ${titleAlign}`}>
              <h5 className="fs-1_5 font-weight-bold m-0">{title}</h5>
            </div>
          ))}
        {text && (
          <div className={textAlign}>
            <RichText
              text={text.raw}
              blocks={{ paragraph: { className: "fs-1_25 p-0" } }}
            />
          </div>
        )}
      </div>
    </div>
  </div>
);

const getColNumber = (columnsNumber) => {
  switch (columnsNumber) {
    case "4":
      return 3;
    case "3":
      return 4;
    case "2":
    default:
      return 6;
  }
};

const getColClass = (columnsNumber, index) => {
  if (index === 0) {
    return "pr-md-3rem";
  }

  if (index + 1 === columnsNumber) {
    return "pl-md-3rem";
  }

  return "px-md-3rem";
};
