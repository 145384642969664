import "../scss/app.scss";
import React from "react";
import { graphql } from "gatsby";
import { Buttons } from "../component/Buttons";
import { Counters } from "../component/Counters";
import { Contact } from "../component/Contact";
import { FinePrints } from "../component/FinePrints";
import { Footer } from "../component/Footer";
import { Identifications } from "../component/Identifications";
import { Navbar } from "../component/Navbar";
import { SectionImage } from "../component/SectionImage";
import { Packages } from "../component/Packages";
import { Partners } from "../component/Partners";
import { Pictograms } from "../component/Pictograms";
import { Spacer } from "../component/Spacer";
import { SectionText } from "../component/SectionText";
import { SectionFaq } from "../component/SectionFaq";
import { SectionTitleText } from "../component/SectionTitleText";
import { SectionTextAnimation } from "../component/SectionTextAnimation";
import { SectionTextImage } from "../component/SectionTextImage";
import { SectionTopWave } from "../component/SectionTopWave";
import { SectionColumns } from "../component/SectionColumns";
import { usePageInit } from "../component/hook/usePageInit";
import { SectionTwoColumns } from "../component/SectionTwoColumns";
import { Cookies } from "../component/Cookies";
import { SectionContactFormSpecialOffer } from "../component/SectionContactFormSpecialOffer";
import { Feedbacks } from "../component/Feedbacks";
import AppHelmet from "../component/AppHelmet";

const components = {
  ContentfulNavbar: Navbar,
  ContentfulSectionTopWave: SectionTopWave,
  ContentfulSectionTextImage: SectionTextImage,
  ContentfulCounters: Counters,
  ContentfulPartners: Partners,
  ContentfulSectionTextAnimation: SectionTextAnimation,
  ContentfulSectionTitleText: SectionTitleText,
  ContentfulSectionText: SectionText,
  ContentfulSectionColumns: SectionColumns,
  ContentfulContact: Contact,
  ContentfulFooter: Footer,
  ContentfulPictograms: Pictograms,
  ContentfulFeedbacks: Feedbacks,
  ContentfulSectionImage: SectionImage,
  ContentfulSectionButtons: Buttons,
  ContentfulIdentifications: Identifications,
  ContentfulSpacer: Spacer,
  ContentfulPackages: Packages,
  ContentfulFinePrints: FinePrints,
  ContentfulSectionFaq: SectionFaq,
  ContentfulSectionContactFormSpecialOffer: SectionContactFormSpecialOffer,
  ContentfulSectionTwoColumns: SectionTwoColumns,
};

function Page({ data, pageContext }) {
  const { title } = pageContext;
  const {
    contentfulPage: { sections, seoDescription },
  } = data;
  const { popup } = usePageInit();

  return (
    <main>
      <AppHelmet title={title} description={seoDescription} />
      {popup}
      {sections.map(({ id, __typename, ...props }) => {
        const SectionTag = components[__typename];

        return SectionTag && <SectionTag {...props} key={id} />;
      })}
      <Cookies />
    </main>
  );
}

export default Page;

export const pageSectionsQuery = graphql`
  query($id: String!) {
    contentfulPage(id: { eq: $id }) {
      id
      title
      seoDescription
      sections {
        ... on ContentfulNavbar {
          id
          __typename
          logo {
            fluid {
              src
            }
          }
          navigationItems {
            items {
              id
              url
              label
              type
              columnNumber
              dropdownItems {
                id
                url
                label
                description
                text {
                  raw
                }
                image {
                  fluid {
                    src
                  }
                }
              }
            }
          }
          companyInformation {
            contactEmail
            copyrights {
              raw
            }
            socialMedia {
              alt
              id
              url
              icon {
                fluid {
                  src
                }
              }
            }
          }
        }
        ... on ContentfulFooter {
          id
          __typename
          logo {
            fluid {
              src
            }
          }
          copyrights {
            raw
          }
          termsAndConditions {
            file {
              url
            }
          }
          contactEmail
          social {
            url
            icon {
              fluid {
                src
              }
            }
            alt
          }
          navigation {
            id
            url
            label
          }
        }
        ... on ContentfulContact {
          id
          __typename
          columnLeft {
            description {
              raw
            }
            title
            successMessage {
              raw
            }
          }
          columnRight {
            description {
              raw
            }
            description2 {
              raw
            }
            title
            phone
            email
          }
        }
        ... on ContentfulCounters {
          id
          title
          spaceBottom
          spaceTop
          items {
            caption
            value
          }
        }
        ... on ContentfulPartners {
          id
          items {
            image {
              fluid {
                src
              }
            }
          }
          title
          spaceBottom
          spaceTop
          width
          description {
            raw
          }
          showSlider
          itemsPerSlide
        }
        ... on ContentfulSectionTopWave {
          id
          __typename
          title
          description {
            raw
          }
          image {
            title
            id
            description
            fluid {
              src
            }
          }
          sectionWidth
          imagePosition
          imageWidth
          imageAlign
          variant
          button {
            backgroundColor
            borderColor
            borderWidth
            textColor
            url
            text
            outline
          }
        }
        ... on ContentfulSectionTextImage {
          id
          __typename
          title
          separator
          separatorSize
          description {
            raw
          }
          iconClass
          image {
            fluid {
              src
            }
          }
          imagePosition
          imageWidth
          imageAlign
          textColor
          backgroundColor
          buttons {
            backgroundColor
            borderColor
            borderWidth
            url
            text
            textColor
            outline
          }
          smallText {
            raw
          }
          spaceBottom
          spaceTop
          leftColWidth
          aosOffset
          isPromotionalVideo
        }
        ... on ContentfulSectionTextAnimation {
          id
          __typename
          title
          description {
            raw
          }
          animation
          animationPosition
          animationAlign
          backgroundColor
          spaceBottom
          spaceTop
        }
        ... on ContentfulSectionTitleText {
          id
          __typename
          title
          titleColor
          titleFontSize
          titleFontWeight
          titleAlign
          titlePaddingBottom
          separator
          separatorSize
          backgroundColor
          spaceBottom
          spaceTop
          description {
            raw
          }
          buttons {
            backgroundColor
            borderColor
            borderWidth
            url
            text
            textColor
            outline
          }
        }
        ... on ContentfulPictograms {
          id
          __typename
          backgroundColor
          variant
          titleAndTextColumns {
            id
            title
            titleAlign
            text {
              raw
            }
            textFontSize
            textAlign
            image {
              fluid {
                src
              }
            }
          }
          button {
            backgroundColor
            borderColor
            borderWidth
            textColor
            url
            text
            outline
          }
          pictogramsAlign
          pictogramTitleUppercase
          spaceBottom
          spaceTop
        }
        ... on ContentfulFeedbacks {
          id
          __typename
          title
          items {
            id
            person
            description
            content {
              raw
            }
            image {
              fluid {
                src
              }
            }
          }
          spaceBottom
          spaceTop
        }
        ... on ContentfulSectionImage {
          id
          __typename
          backgroundColor
          image {
            file {
              url
            }
          }
          spaceBottom
          spaceTop
        }
        ... on ContentfulSectionText {
          id
          text {
            raw
          }
          backgroundColor
          spaceBottom
          spaceTop
          width
        }
        ... on ContentfulSectionColumns {
          id
          columns {
            title
            titleAlign
            text {
              raw
            }
            link
            textAlign
            image {
              fluid {
                src
              }
            }
            imageAlign
          }
          columnsNumber
          backgroundColor
          spaceBottom
          spaceTop
          type
        }
        ... on ContentfulSectionButtons {
          id
          __typename
          buttons {
            text
            url
            backgroundColor
            borderColor
            borderWidth
            textColor
            outline
          }
          backgroundColor
          spaceBottom
          spaceTop
          alignButtons
        }
        ... on ContentfulIdentifications {
          id
          __typename
          spaceBottom
          spaceTop
          identifications {
            id
            title
            image {
              fluid {
                src
              }
            }
          }
          description {
            raw
          }
          backgroundColor
          textColor
        }
        ... on ContentfulPackages {
          id
          __typename
          packages {
            description {
              raw
            }
            price
            oldPrice
            title
          }
          button {
            backgroundColor
            borderColor
            borderWidth
            textColor
            url
            text
            outline
          }
        }
        ... on ContentfulFinePrints {
          id
          __typename
          finePrints {
            description {
              raw
            }
          }
        }
        ... on ContentfulSectionFaq {
          id
          items {
            title
            text {
              raw
            }
          }
        }
        ... on ContentfulSpacer {
          id
          __typename
          size
          backgroundColor
        }
        ... on ContentfulSectionContactFormSpecialOffer {
          id
          spaceBottom
          spaceTop
          backgroundColor
          columnLeft {
            ... on ContentfulContactColumn {
              id
              __typename
              title
              description {
                raw
              }
              separator
              successMessage {
                raw
              }
              backgroundColor
              consents {
                inputName
                text {
                  raw
                }
              }
              button {
                text
                textColor
                backgroundColor
                borderColor
                borderWidth
                outline
              }
            }
            ... on ContentfulImageColumn {
              id
              __typename
              title
              description {
                raw
              }
              image {
                fluid {
                  src
                }
              }
              imageAlign
              backgroundColor
              buttons {
                backgroundColor
                borderColor
                borderWidth
                url
                text
                textColor
                outline
              }
            }
          }
          columnRight {
            ... on ContentfulContactColumn {
              id
              __typename
              title
              description {
                raw
              }
              separator
              successMessage {
                raw
              }
              backgroundColor
              consents {
                inputName
                text {
                  raw
                }
              }
              button {
                text
                textColor
                backgroundColor
                borderColor
                borderWidth
                outline
              }
            }
            ... on ContentfulImageColumn {
              id
              __typename
              title
              description {
                raw
              }
              image {
                fluid {
                  src
                }
              }
              imageAlign
              backgroundColor
            }
          }
        }
        ... on ContentfulSectionTwoColumns {
          id
          spaceBottom
          spaceTop
          backgroundColor
          columnLeft {
            ... on ContentfulContactColumn {
              id
              __typename
              title
              separator
              successMessage {
                raw
              }
              backgroundColor
              consents {
                inputName
                text {
                  raw
                }
              }
              button {
                text
                textColor
                backgroundColor
                borderColor
                borderWidth
                outline
              }
            }
            ... on ContentfulImageColumn {
              id
              __typename
              title
              description {
                raw
              }
              image {
                fluid {
                  src
                }
              }
              imageAlign
              backgroundColor
            }
          }
          columnRight {
            ... on ContentfulContactColumn {
              id
              __typename
              title
              separator
              successMessage {
                raw
              }
              backgroundColor
              consents {
                inputName
                text {
                  raw
                }
              }
              button {
                text
                textColor
                backgroundColor
                borderColor
                borderWidth
                outline
              }
            }
            ... on ContentfulImageColumn {
              id
              __typename
              title
              description {
                raw
              }
              image {
                fluid {
                  src
                }
              }
              imageAlign
              backgroundColor
            }
          }
        }
      }
    }
  }
`;
