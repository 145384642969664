import React, { useRef, useState } from "react";
import { Section } from "./Section";
import { RichText } from "./RichText";
import { WaveBackground } from "./WaveBackground";
import PrimaryLinkButton from "./PrimaryLinkButton";
import BlueWave from "../images/blue-wave.png";
import { Button } from "./Button";
import { PopupEbook } from "./popup/PopupEbook";

export const SectionTopWave = ({
  title,
  description,
  image,
  button,
  sectionWidth,
  imageWidth = 100,
  imageAlign = "center",
  imagePosition = "Middle",
  variant,
}) => {
  const [indicatorRef, setIndicatorRef] = useState(null);
  const [showPopup, setShowPopup] = useState(false);
  const ref = useRef(null);
  const getOffsetTop = () => {
    if (imagePosition === "Middle") {
      return indicatorRef.offsetTop / 2.5;
    }

    return ref.current.clientHeight - indicatorRef.offsetTop;
  };

  const imageColumn = image && (
    <ImageColumn
      imageSrc={image.fluid.src}
      imagePosition={imagePosition}
      imageAlign={imageAlign}
      imageWidth={imageWidth}
      aosOffset={0}
      ebook={variant === "Ebook - form"}
    />
  );

  return (
    <>
      {indicatorRef && (
        <WaveBackground getOffsetTop={getOffsetTop} image={BlueWave} />
      )}
      <div
        className="position-relative bg-md-blue-gradient"
        style={{ marginBottom: variant === "Ebook - form" ? "-4rem" : "0" }}
        ref={ref}
      >
        <Section width={sectionWidth} className="py-3rem py-lg-5rem">
          {imagePosition === "Left" && imageColumn}
          <TextColumn
            title={title}
            description={description}
            image={image}
            imagePosition={imagePosition}
            imageWidth={imageWidth}
            imageAlign={imageAlign}
            button={button}
            setIndicatorRef={setIndicatorRef}
            ebookButton={variant === "Ebook - form"}
            setShowPopup={setShowPopup}
          />
          {imagePosition === "Right" && imageColumn}
        </Section>
      </div>
      {showPopup && <PopupEbook setShowPopup={setShowPopup} button={button} />}
      {imagePosition === "Middle" && <div className="h-0 h-lg-5rem" />}
    </>
  );
};

const getTextalign = (imagePosition) => {
  switch (imagePosition) {
    case "Right":
      return "left";
    case "Left":
      return "right";
    case "Middle":
    default:
      return "center";
  }
};

const TextColumn = ({
  title,
  description,
  image,
  imagePosition,
  imageWidth,
  imageAlign,
  button,
  setIndicatorRef,
  ebookButton,
  setShowPopup,
}) => {
  const textAlign = getTextalign(imagePosition);
  const textCol = imagePosition !== "Middle" ? "col-lg-6" : "";

  const renderButton = () => {
    if (ebookButton && setShowPopup) {
      const { backgroundColor, borderWidth, outline, text, textColor } = button;
      return (
        <div
          className={`d-block ${imagePosition === "Middle" ? "d-lg-none" : ""}`}
        >
          <Button
            onClick={() => setShowPopup(true)}
            children={text}
            round={4}
            className={`w-100 w-sm-auto mb-2rem py-2 text-uppercase px-3rem fs-0_875 fs-sm-1 ${borderWidth} ${textColor} ${backgroundColor} ${outline}`}
          />
        </div>
      );
    } else {
      return (
        <div
          className={`d-block ${imagePosition === "Middle" ? "d-lg-none" : ""}`}
        >
          <PrimaryLinkButton
            button={{ ...button, outline: "btn-outline-transparent" }}
          />
        </div>
      );
    }
  };

  return (
    <div
      className={`col-12 ${textCol} d-flex flex-column text-center text-lg-${textAlign}`}
    >
      {title && (
        <h2 className="fs-2 fs-xl-2_25 fs-xxl-2_75 extra-bold pb-4 c-black">
          {title}
        </h2>
      )}
      {description && <RichText className="pb-2rem" text={description.raw} />}
      {imagePosition === "Middle" && image && (
        <div className={`text-center text-lg-${imageAlign}`}>
          <img
            src={image.fluid.src}
            alt="icon"
            className={`pb-2rem max-w-${imageWidth}`}
          />
        </div>
      )}
      {button && renderButton()}
      <div ref={(el) => setIndicatorRef(el)} />
    </div>
  );
};

const ImageColumn = ({
  imageSrc,
  imagePosition,
  imageAlign,
  imageWidth,
  aosOffset,
  leftColWidth = 6,
  ebook,
}) => {
  let imageClass = "pr-2rem";
  let colWidth = leftColWidth;

  if (imagePosition === "Right") {
    imageClass = "pl-2rem";
    colWidth = 12 - parseInt(leftColWidth);
  }

  return (
    <div
      className={`col-12 order-11 col-lg-${colWidth || 6} text-center text-lg-${
        imageAlign || "center"
      }`}
    >
      <img
        style={{ marginTop: ebook ? "-2.5rem" : "0" }}
        className={`d-inline-block max-w-${
          imageWidth || 100
        } px-0 ${imageClass}`}
        src={imageSrc}
        alt="section three"
        data-aos="fade-up"
        data-aos-offset={aosOffset || 0}
      />
    </div>
  );
};
